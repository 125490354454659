import React from 'react'
import Header from '../structure/Header';
import Leading from '../widgets/Leading';
import { useTranslation } from "react-i18next"

const Reinsurances = () => {
    const { t } = useTranslation();

    return (
        <div className="first-box">
            <Header></Header>
            <div className="content-box">
                <Leading label={t('myReinsurances')} />
            </div>
        </div>
    );
}

export default Reinsurances