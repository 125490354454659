import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const TextField = ({ label, type = "text", value, onChange }) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    return (
        <div className="input-container">
            <input
                type={isPasswordVisible ? "text" : type}
                value={value}
                onChange={onChange}
                className={`input ${value ? 'filled' : ''}`}
                required
            />
            <label className={`input-label ${value ? 'filled' : ''}`}>
                {label}
            </label>
            {type === "password" && (
                <button
                    type="button"
                    className="toggle-password"
                    onClick={togglePasswordVisibility}
                >
                    {isPasswordVisible ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                </button>
            )}
        </div>
    );
};

export default TextField;