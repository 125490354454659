import React from 'react'
import { IMAGE_ENDPOINTS } from '../../config/api';
import { useTranslation } from "react-i18next";
import { getCurrencySymbol } from '../../utils/currency';
import { formatNumberWithCommas } from '../../utils/formatter';
import { useNavigate } from 'react-router-dom';

const AdvertCard = ({ advert, type = "view" }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const date = String(advert.createDate[2]).padStart(2, '0') + "/" + String(advert.createDate[1]).padStart(2, '0') + "/" + advert.createDate[0];

    const buttonAction = () => {
        if (type === "view") {
            const data = {
                advert: advert
            };
            navigate('/policy/' + advert.id, { state: data });
        }
    };

    return (
        <div className="advert-card">
            <div className="corp">
                <div className="line">
                    <img className="img-round" src={IMAGE_ENDPOINTS.CORPORATE + advert.corporateID + ".png"} alt={advert.corporateName.toLowerCase().replace(" ", "_") + "_logo"} />
                    <p className="content-second">{date}</p>
                </div>
                <p className="content-second">{advert.corporateName}</p>
            </div>
            <div className="info">
                <p className="title-medium title">{advert.title}</p>
                <p className="content desc">{advert.description}</p>
            </div>
            <div className="amount">
                <p className="content">{t('amountOfPremium')}</p>
                <p className="content-focus">{formatNumberWithCommas(advert.amountOfPremium)}{getCurrencySymbol(advert.currency)}</p>
            </div>
            <button className={type === "edit" ? "btn btn-edit" : "btn"} onClick={() => buttonAction()}>{type === "edit" ? t('edit') : t('details')}</button>
        </div>
    )
}

export default AdvertCard