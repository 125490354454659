import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { AuthWrapper } from './auth/AuthWrapper';
import ScrollToTop from './utils/scroll';


function App() {

  return (
    <BrowserRouter>
      <ScrollToTop />
      <AuthWrapper />
    </BrowserRouter>
  );
}

export default App;
