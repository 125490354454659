import React from 'react'
import Header from '../structure/Header'
import Leading from '../widgets/Leading'
import { IMAGE_ENDPOINTS } from '../../config/api'
import DetailItem from '../widgets/DetailItem'
import { useLocation } from 'react-router-dom';
import { formatNumberWithCommas } from '../../utils/formatter';
import { getCurrencySymbol } from '../../utils/currency';
import { useTranslation } from "react-i18next";

const AdvertDetail = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { advert } = location.state || {};
    const startDate = String(advert.startDate[2]).padStart(2, '0') + "/" + String(advert.startDate[1]).padStart(2, '0') + "/" + advert.startDate[0];
    const endDate = String(advert.endDate[2]).padStart(2, '0') + "/" + String(advert.endDate[1]).padStart(2, '0') + "/" + advert.endDate[0];
    const birthDate = String(advert.birthDate[2]).padStart(2, '0') + "/" + String(advert.birthDate[1]).padStart(2, '0') + "/" + advert.birthDate[0];

    return (
        <div className="first-box">
            <Header />
            <div className="content-box">
                <Leading label={advert.title} searchBox={false} />
                
                <div className="row row-wrap">
                    <div className="col">
                        <div className="detail-card row">
                            <img src={IMAGE_ENDPOINTS.CORPORATE + advert.corporateID + ".png"} alt={advert.corporateName.toLowerCase().replace(" ", "_") + "_logo"} className="img-round" />
                            <p className="title-medium">{advert.corporateName}</p>
                        </div>
                        <div className="detail-card row">
                            <div className="icon-circle icon-circle-red"></div>
                            <p className="title-medium">{advert.type}</p>
                        </div>
                        <div className="detail-card row">
                            <div className="icon-circle icon-circle-green title-huge">#</div>
                            <p className="title-medium">{advert.policyID}</p>
                        </div>
                    </div>

                    <div className="detail-card nocs">
                        <p className="content">{advert.description}</p>
                    </div>

                    <div className="detail-card">
                        <div className="col">
                            <DetailItem title={"Start Date"} value={startDate} />
                            <DetailItem title={"End Date"} value={endDate} />
                            <DetailItem title={"Premium"} value={formatNumberWithCommas(advert.amountOfPremium) + getCurrencySymbol(advert.currency)} />
                            <DetailItem title={"Payment Period"} value={advert.paymentPeriod} isLast={true} />
                        </div>
                    </div>
                </div>

                <div className="detail-titlebox title-big">{t('insuredInfos')}</div>
                <div className="row row-wrap">
                    <div className="detail-card">
                        <div className="col">
                            <DetailItem title={t('name')} value={advert.name} />
                            <DetailItem title={t('surname')} value={advert.surname} />
                            <DetailItem title={t('nationality')} value={advert.nationality} />
                            <DetailItem title={t('nationalID')} value={advert.nationalID} isLast={true} />
                        </div>
                    </div>
                    <div className="detail-card">
                        <div className="col">
                            <DetailItem title={t('phone')} value={advert.phone} />
                            <DetailItem title={t('email')} value={advert.email} />
                            <DetailItem title={t('birthDate')} value={birthDate} />
                            <DetailItem title={t('address')} value={advert.address} isLast={true} />
                        </div>
                    </div>
                </div>

                <div className="detail-titlebox title-big">{t('coverageDetails')}</div>
                <div className="row row-wrap">
                    <div className="detail-card nocs">
                        <p className="content">{advert.coverageDetails}</p>
                    </div>
                </div>

                <div className="detail-titlebox title-big">{t('paymentDetails')}</div>
                <div className="row row-wrap">
                    <div className="detail-card nocs">
                        <p className="content">{advert.paymentDetails}</p>
                    </div>
                </div>

                <div className="detail-titlebox title-big">{t('termsAndConditions')}</div>
                <div className="row row-wrap">
                    <div className="detail-card nocs">
                        <p className="content">{advert.termsAndConditions}</p>
                    </div>
                </div>

                <div className="detail-titlebox title-big">{t('otherDetails')}</div>
                <div className="row row-wrap">
                    <div className="detail-card nocs">
                        <p className="content">{advert.otherDetails}</p>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default AdvertDetail