
import Add from "../pages/Add";
import AdvertDetail from "../pages/AdvertDetail";
import Corporate from "../pages/Corporate";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Offers from "../pages/Offers";
import Otp from "../pages/Otp";
import Register from "../pages/Register";
import Reinsurances from "../pages/Reinsurances";
import Settings from "../pages/Settings";

export const nav = [
    { path: "/", name: "login", element: <Login />, isMenu: false, isPrivate: false },
    { path: "/home", name: "Home", element: <Home />, isMenu: true, isPrivate: true },
    { path: "/register", name: "Register", element: <Register />, isMenu: false, isPrivate: false },
    { path: "/add", name: "Add", element: <Add />, isMenu: true, isPrivate: true },
    { path: "/offers", name: "Offers", element: <Offers />, isMenu: true, isPrivate: true },
    { path: "/corporate", name: "Corporate", element: <Corporate />, isMenu: true, isPrivate: true },
    { path: "/reinsurances", name: "Reinsurances", element: <Reinsurances />, isMenu: true, isPrivate: true },
    { path: "/settings", name: "Settings", element: <Settings />, isMenu: true, isPrivate: true },
    { path: "/otp", name: "OTP", element: <Otp />, isMenu: false, isPrivate: false },
    { path: "/policy/:id", name: "Advert Detail", element: <AdvertDetail />, isMenu: false, isPrivate: false },
]