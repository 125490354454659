import React, { useState } from 'react';
import TextField from '../widgets/TextField';
import { AuthData } from "../../auth/AuthWrapper"
import { useTranslation } from "react-i18next"
import { validateLen, validateEmail } from '../../utils/validator';
import toast from 'react-hot-toast';

const Login = () => {
    const { t } = useTranslation();
    const { login } = AuthData();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const loginButtonAction = async () => {
        try {
            if (validateEmail(email, t) && validateLen(password, t)) {
                await login(email, password, t)
            }
        } catch (error) {
            toast.error(t('ERR'));
        }
    };

    return (
        <div className='container'>
            <div className="login-logo"><img className='login-logo-2' src="../../assets/images/png/logo-png.png" alt="" /></div>
            <div className="login-card">
                <TextField label={t('email')} type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                <TextField label={t('password')} type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            <button className='btn' onClick={() => loginButtonAction()}>{t('login')}</button>
            <div className='forgot-pass'>
                <p>{t('registerInfo')} <a href='/register'>{t('signup')}</a></p>
            </div>
        </div>
    );
}

export default Login;