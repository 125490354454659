import React from 'react'

const Leading = ({ label, searchBox = false }) => {
    return (
        <div>
            <div className="head">
                {searchBox
                    ? <div className="search-box">
                        <span className="icon">search</span>
                        <input className="title-light" type="text" placeholder={ label }/>
                    </div>
                    : <h2 className="title-huge">{label}</h2>
                }
                <div className="icons">
                    <span className="icon">person</span>
                </div>
            </div>
        </div>
    )
}

export default Leading