import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import TextField from '../widgets/TextField';
import { API_ENDPOINTS } from '../../config/api';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { validateLen, validateEmail, validateNames } from '../../utils/validator';

const Register = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const reason = "register";
    const type = "email";

    const registerButtonAction = async () => {
        if (validateNames(name, t) && validateNames(surname, t) && validateEmail(email, t) && validateLen(password, t)) {
            const requestBody = {
                "reason": reason,
                "type": type,
                "to": email
            };

            try {
                const response = await fetch(API_ENDPOINTS.OTP, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody),
                });

                if (response.ok) {
                    const data = await response.json();
                    if (data['code'] === "OK") {
                        const otpData = {
                            request: {
                                "name": name,
                                "surname": surname,
                                "email": email,
                                "password": password
                            },
                            type: type,
                            reason: reason,
                            to: email,
                        };
                        navigate('/otp', { state: otpData });
                    } else {
                        toast.error(data['messages']);
                    }
                } else {
                    toast.error(t('ERR'));
                }
            } catch (error) {
                console.log(error);
                toast.error(t('ERR'));
            }
        }
    }

    return (
        <div className='container'>
            <div className="login-logo"></div>
            <div className="login-card">
                <TextField label={t('name')} value={name} onChange={(e) => setName(e.target.value)} />
                <TextField label={t('surname')} value={surname} onChange={(e) => setSurname(e.target.value)} />
                <TextField label={t('email')} value={email} onChange={(e) => setEmail(e.target.value)} />
                <TextField label={t('password')} type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            <button className='btn' onClick={registerButtonAction}>{t('register')}</button>
            <div className='forgot-pass'>
                <p>{t('loginInfo')} <a href='/'>{t('signin')}</a></p>
            </div>
        </div>
    );
}

export default Register;