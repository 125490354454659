import { createContext, useContext } from "react"
import { RenderRoutes } from "../components/structure/RenderNavigations";
import { API_ENDPOINTS } from '../config/api';
import { useNavigate } from 'react-router-dom';
import { toast, Toaster } from "react-hot-toast";

const AuthContext = createContext();
export const AuthData = () => useContext(AuthContext);


export const AuthWrapper = () => {
    const navigate = useNavigate();
    const themeColorMeta = document.getElementById('theme-color');

    const login = async (email, password, t) => {
        const requestBody = {
            "email": email,
            "password": password
        };

        const response = await fetch(API_ENDPOINTS.LOGIN, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        if (response.ok) {
            const data = await response.json();
            if (data['code'] === "OK") {
                localStorage.setItem('token', data['token']);
                localStorage.setItem('expireTime', data['expireTime']);
                themeColorMeta.setAttribute('content', '#FFFFFF');
                navigate("/home");
            } else {
                toast.error(data['messages']);
            }
        } else {
            toast.error(t('ERR'));
        }
    }
    const logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("expireTime");
        themeColorMeta.setAttribute('content', '#F2F2F2');
        navigate("/");
    }

    const isAuthenticated = () => {
        const token = localStorage.getItem('token');
        const expireTime = localStorage.getItem('expireTime');
        return (token !== null && expireTime !== null && Number(expireTime) > (new Date()).getTime());
    };


    return (
        <AuthContext.Provider value={{ login, logout, isAuthenticated }}>
            <RenderRoutes />
            <Toaster toastOptions={{className: "react-hot-toast"}} />
        </AuthContext.Provider>
    );

}