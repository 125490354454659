import toast from "react-hot-toast";


function validateLen(input, t) {
    if (!input || input.trim() === "") {
        toast.error(t('ERR-AUTH001'));
        return false;
    }

    if (input.length > 255) {
        toast.error(t('ERR-AUTH002'));
        return false;
    }

    return true;
}

function validateEmail(input, t) {
    if (!input || input.trim() === "") {
        toast.error(t('ERR-AUTH001'));
        return false;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(input)) {
        toast.error(t('ERR-AUTH003'));
        return false;
    }

    if (input.length > 255) {
        toast.error(t('ERR-AUTH002'));
        return false;
    }

    return true;
}

function validateNames(input, t) {
    if(!validateLen(input, t)) {
        return false;
    }

    const letterPattern = /^[A-Za-z\s]+$/;
    if (!letterPattern.test(input)) {
        toast.error(t('ERR-AUTH004'));
        return false;
    }

    return true;

}

export { validateLen, validateEmail, validateNames }