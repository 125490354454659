import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { API_ENDPOINTS } from '../../config/api';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Otp = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { request, type, reason, to } = location.state || {};
    const navigate = useNavigate();
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef([]);
    const [countdown, setCountdown] = useState(90);

    useEffect(() => {
        if (countdown > 0 && to != null) {
            const timer = setInterval(() => {
                setCountdown(prev => prev - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
    });

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes < 10 ? '0' : ''}${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    const resendAction = async () => {
        const requestBody = {
            "reason": reason,
            "type": type,
            "to": to
        };

        try {
            const response = await fetch(API_ENDPOINTS.OTP, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (response.ok) {
                const data = await response.json();
                if (data['code'] === "OK") {
                    setOtp(prevOtp => prevOtp.map(value => (value !== '' ? '' : value)));
                    setCountdown(90);
                } else {
                    toast.error(data['messages']);
                }
            } else {
                toast.error(t('ERR'));
            }
        } catch (error) {
            console.log(error);
            toast.error(t('ERR'));
        }
    };

    const handleChange = (index, value) => {
        if (/^[0-9]?$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (value && index < otp.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace' && !otp[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const register = async () => {
        const requestBody = request;

        try {
            const response = await fetch(API_ENDPOINTS.REGISTER, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (response.ok) {
                const data = await response.json();
                if (data['code'] === "OK") {
                    navigate("/");
                    toast.success(t('SCS-AUTH001'));
                } else {
                    toast.error(data['messages']);
                }
            } else {
                toast.error(t('ERR'));
            }
        } catch (error) {
            toast.error(t('ERR'));
        }
    };

    const continueButtonAction = async () => {
        if(countdown <= 0) {
            toast.error(t('ERR-OTP001'));
            return;
        }

        if (otp.join('').length === 6) {
            const requestBody = {
                "type": type,
                "reason": reason,
                "to": to,
                "key": otp.join('')
            };
    
            try {
                const response = await fetch(API_ENDPOINTS.VALIDATION, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody),
                });
    
                if (response.ok) {
                    const data = await response.json();
                    if (data['code'] === "OK") {
                        if (reason === "register") {
                            await register();
                        }
                    } else {
                        toast.error(data['messages']);
                    }
                } else {
                    toast.error(t('ERR'));
                }
            } catch (error) {
                toast.error(t('ERR'));
            }
        } else {
            toast.error(t('ERR-AUTH001'));
        }
    };

    return (
        <div className='container'>
            <div className="login-logo"></div>
            <div className="otp-container">
                <div>
                    <div className="otp-container">
                        {otp.map((value, index) => (
                            <input
                                key={index}
                                type="text"
                                value={value}
                                onChange={(e) => handleChange(index, e.target.value)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                maxLength={1}
                                ref={(ref) => (inputRefs.current[index] = ref)}
                                className="otp-input"
                            />
                        ))}
                    </div>
                    <div className='countdown-timer'>
                        <p className='content'>{t('remainingTime')}: <span className='title-small'>{formatTime(countdown)}</span></p>
                        {(countdown === 0) && (
                            <p className='content-focus resend' onClick={ () => resendAction() } >{t('resend')}</p>
                        )}
                    </div>
                </div>
            </div>
            <button className='btn' onClick={continueButtonAction}>{t('continue')}</button>
        </div>
    );
}

export default Otp;