import React from 'react'

const DetailItem = ({ title, value, isLast=false }) => {
    return (
        <div className={!isLast ? "row item-row" : "row" }>
            <div className="icon-circle icon-circle-grey"></div>
            <div>
                <p className="title">{ title }</p>
                <p className="content">{ value }</p>
            </div>
        </div>
    )
}

export default DetailItem