import { Navigate, Route, Routes } from "react-router-dom";
import { AuthData } from "../../auth/AuthWrapper";
import { nav } from "./navigations";


export const RenderRoutes = () => {

    const { isAuthenticated } = AuthData();

    return (
        <Routes>
            {nav.map((r, i) => {
                if (r.isPrivate) {
                    return isAuthenticated() ? (
                        <Route key={i} path={r.path} element={r.element} />
                    ) : (
                        <Route key={i} path={r.path} element={<Navigate to="/" />} />
                    );
                }
                return <Route key={i} path={r.path} element={r.element} />;
            })}
        </Routes>
    )
}
