const BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.takagate.com';
const BASE_IMAGE_URL = 'https://app.takagate.com/images';

export const API_ENDPOINTS = {
  LOGIN: `${BASE_URL}/user/login`,
  REGISTER: `${BASE_URL}/user/register`,
  OTP: `${BASE_URL}/verification/send/auth-key`,
  VALIDATION: `${BASE_URL}/verification/validate/auth-key`,
  QUERY_ADVERT: `${BASE_URL}/advert/query`,
  CORPORATE_QUERY_ADVERT: `${BASE_URL}/advert/corporate-query`,
};

export const IMAGE_ENDPOINTS = {
  CORPORATE: `${BASE_IMAGE_URL}/corporate/`,
};