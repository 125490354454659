import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthData } from "../../auth/AuthWrapper"
import { useTranslation } from "react-i18next"

const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = AuthData();
  const [isNavIconVisible, setIsNavIconVisible] = useState(true);

  const logoutAction = () => {
    logout();
  }

  const homeAction = () => {
    navigate("/home");
  }

  const offersAction = () => {
    navigate("/offers");
  }

  const reinsurancesAction = () => {
    navigate("/reinsurances");
  }

  const corporateAction = () => {
    navigate("/corporate");
  }

  const settingsAction = () => {
    navigate("/settings");
  }

  const addAction = () => {
    navigate("/add");
  }

  const toggleNavIcon = () => {
    setIsNavIconVisible(prev => !prev);
  };

  return (
    <div className="nav-bar">
      <ul>
        <li className='nav-logo'></li>
        <li className='nav-collapse' onClick={toggleNavIcon}>{isNavIconVisible ? 'menu' : 'close'}</li>
        <div className={`nav-icon ${isNavIconVisible ? 'block' : 'none'}`}>
          <li><i className={location.pathname === "/home" ? "nav-icon nav-icon-filled" : "nav-icon"} onClick={() => homeAction()}>grid_view</i></li>
          <li><i className={location.pathname === "/offers" ? "nav-icon nav-icon-filled" : "nav-icon"} onClick={() => offersAction()}>assignment</i></li>
          <li><i className={location.pathname === "/reinsurances" ? "nav-icon nav-icon-filled" : "nav-icon"} onClick={() => reinsurancesAction()}>assignment_turned_in</i></li>
          <li><i className={location.pathname === "/corporate" ? "nav-icon nav-icon-filled" : "nav-icon"} onClick={() => corporateAction()}>home_work</i></li>
          <li><i className={location.pathname === "/settings" ? "nav-icon nav-icon-filled" : "nav-icon"} onClick={() => settingsAction()}>settings</i></li>
          <li><i className="nav-icon nav-icon-add" onClick={() => addAction()}>add_box</i></li>
          <li>
            <div className='nav-icon shadow-box'>
              <button className='btn' onClick={() => logoutAction()}>{t('logout')}</button>
            </div>
          </li>
        </div>
      </ul>

      <FontAwesomeIcon className='nav-icon nav-icon-bottom' onClick={() => logoutAction()} icon={faRightFromBracket} />
    </div>
  )
}

export default Header