import React, { useState, useEffect } from 'react';
import Header from '../structure/Header';
import Leading from '../widgets/Leading';
import { useTranslation } from "react-i18next";
import { API_ENDPOINTS } from '../../config/api';
import AdvertCard from '../widgets/AdvertCard';
import toast from 'react-hot-toast';

const Corporate = () => {
    const { t } = useTranslation();
    const [adverts, setAdverts] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAdverts = async () => {
            try {
                setLoading(true);
                const response = await fetch(API_ENDPOINTS.CORPORATE_QUERY_ADVERT, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    if(data['code'] === "OK") {
                        setAdverts(data.adverts);
                    }else {
                        toast.error(data['messages']);
                    }
                }else {
                    toast.error(t('ERR'));
                }
            } catch (err) {
                toast.error(t('ERR'));
            } finally {
                setLoading(false);
            }
        };
        fetchAdverts();
    }, [t]);

    return (
        <div className="first-box">
            <Header></Header>
            <div className="content-box">
                <Leading label={t('myCorporateAdvert')} />
                <div className="element-frame">
                    {loading ? (
                        null
                    ) : Array.isArray(adverts) && adverts.length > 0 ? (
                        <ul>
                            {adverts.map((advert) => (
                                <li key={advert.id}>
                                    <AdvertCard advert={advert} type='edit' />
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="no-content">
                            <p className='title-light'>{t('noAdverts')}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Corporate