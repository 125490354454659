const currencySymbols = {
    "USD": "$",
    "EUR": "€",
    "GBP": "£",
    "JPY": "¥",
    "CHF": "CHF",
    "CAD": "C$",
    "AUD": "A$",
    "NZD": "NZ$",
    "CNY": "¥",
    "INR": "₹",
    "RUB": "₽",
    "BRL": "R$",
    "MXN": "$",
    "ZAR": "R",
    "TRY": "₺",
    "SGD": "S$",
    "KRW": "₩",
    "NOK": "kr",
    "SEK": "kr",
    "DKK": "kr",
    "TWD": "NT$",
    "CZK": "Kč",
    "HUF": "Ft",
    "PLN": "zł",
    "IDR": "Rp",
    "MYR": "RM",
    "PHP": "₱",
    "VND": "₫",
    "ARS": "$",
    "COP": "$",
    "CLP": "$",
    "UYU": "$",
    "TTD": "$",
    "BBD": "$",
    "JMD": "$"
};

const getCurrencySymbol = (isoCode) => {
    return currencySymbols[isoCode] || isoCode;
}

export { getCurrencySymbol }